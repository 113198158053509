import { minePrepressChangeColorTaskService, mineTypesettingTaskService, mineFinishedProductTaskService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import {
  ArchiveStatusEnum,
  CloseStatusEnum,
  PrepressActionEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  PrepressTypeEnum,
  ProjectItemTypeEnum
} from '@/resource/enum';
import { PrepressTaskInfo } from '@/resource/model';
import { getTimeDiff, isNullOrUndefinedForBaseType, messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class PrepressTaskDetails extends mixins(DialogMixin) {
  @Prop({ type: Number, required: true })
  public prepressId!: number;
  @Prop({ type: Number, required: true })
  public currentCompleteItemType!: number;

  public prepress: PrepressTaskInfo | null = null;

  public activeTab: 'details' | 'progress' | 'changeRecord' = 'details';

  public get itemType(): string {
    return translation(`projectItemType.${ProjectItemTypeEnum[this.prepress!.itemType]}`);
  }

  public get visibleSize(): string {
    return `${this.prepress!.visibleWidth}mm × ${this.prepress!.visibleHeight}mm`;
  }

  public get finishSize(): string {
    return `${this.prepress!.finishWidth}mm × ${this.prepress!.finishHeight}mm`;
  }

  public get screenArea(): string {
    return ((this.prepress!.finishWidth / 1000) * (this.prepress!.finishHeight / 1000)).toFixed(6);
  }

  public get screenRate(): string {
    return (this.prepress!.visibleWidth / this.prepress!.visibleHeight).toFixed(3);
  }

  public get prepressCrafts(): string {
    if (this.prepress!.itemType === PrepressTypeEnum.changeColor) {
      return translation(`prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[this.prepress!.prepressCrafts]}`);
    }
    return translation(`prepressLayoutCrafts.${PrepressLayoutCraftsEnum[this.prepress!.prepressCrafts]}`);
  }

  public get isConsumeRemake(): string {
    if (isNullOrUndefinedForBaseType(this.prepress!.attritionFlag)) {
      return '--';
    }
    return translation(`common.${this.prepress!.attritionFlag === 0 ? 'no' : 'yes'}`);
  }

  public get isShowArchiveInfo(): boolean {
    return this.currentCompleteItemType === PrepressTypeEnum.finalized;
  }

  /**
   * 印前状态
   */
  public get prepressStatus(): string {
    const str = translation(`prepressStatus.${PrepressStatusEnum[this.prepress!.prepressStatus]}`);
    return this.prepress!.prepressStatus ? str : '';
  }
  /**
   * 归档状态
   */
  public get archiveStatus(): string {
    return translation(`archiveStatus.${ArchiveStatusEnum[this.prepress!.archiveStatus]}`);
  }
  /**
   * 关闭状态
   */
  public get closeStatus(): string {
    return translation(`closeStatus.${CloseStatusEnum[this.prepress!.closeStatus]}`);
  }

  public getActionName(action: PrepressActionEnum): string {
    return translation(`prepressAction.${PrepressActionEnum[action]}`);
  }

  public dialogOpen(): void {
    if (this.prepressId) {
      this.getPrepressDetails(this.prepressId);
    }
  }

  public dialogClosed(): void {
    this.activeTab = 'details';
  }

  public isUpdated(prop: keyof PrepressTaskInfo): boolean {
    if (!this.prepress || this.prepress.projectItemHistoryList.length === 0) {
      return false;
    }
    return this.prepress.projectItemHistoryList[0].changeLogArr.map(x => x.propName).includes(`projectProduct.${prop}`);
  }

  private getPrepressDetails(id: number): void {
    if (this.currentCompleteItemType === PrepressTypeEnum.changeColor) {
      this.minePrepressChangeColor(id);
    } else if (this.currentCompleteItemType === PrepressTypeEnum.finalized) {
      this.mineFinishedProduct(id);
    } else if (this.currentCompleteItemType === PrepressTypeEnum.typesetting) {
      this.mineTypesetting(id);
    }
  }
  /**
   * 我的改色任务详情
   * @param id
   */
  private minePrepressChangeColor(id: number): void {
    minePrepressChangeColorTaskService
      .getById(id)
      .then(res => {
        res.projectItemHistoryList.forEach(x => {
          x.changeLogArr = x.changeLog ? JSON.parse(x.changeLog) : [];
        });
        res.productionTime =
          res.makeStateTime && res.submitConfirmTime ? getTimeDiff(res.makeStateTime, res.submitConfirmTime) : '';
        this.prepress = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
  /**
   * 我的成品任务详情
   * @param id
   */
  private mineFinishedProduct(id: number): void {
    mineFinishedProductTaskService
      .getById(id)
      .then(res => {
        res.projectItemHistoryList.forEach(x => {
          x.changeLogArr = x.changeLog ? JSON.parse(x.changeLog) : [];
        });
        res.productionTime =
          res.makeStateTime && res.submitConfirmTime ? getTimeDiff(res.makeStateTime, res.submitConfirmTime) : '';
        this.prepress = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
  /**
   * 我的排版任务详情
   * @param id
   */
  private mineTypesetting(id: number): void {
    mineTypesettingTaskService
      .getById(id)
      .then(res => {
        res.projectItemHistoryList.forEach(x => {
          x.changeLogArr = x.changeLog ? JSON.parse(x.changeLog) : [];
        });
        res.productionTime =
          res.makeStateTime && res.submitConfirmTime ? getTimeDiff(res.makeStateTime, res.submitConfirmTime) : '';
        this.prepress = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
