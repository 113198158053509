import { changeColorTaskService, departmentService, personnelService } from '@/api';
import completeManuscriptTaskService from '@/api/prepress-task/complete-manuscript-task';
import { DialogMixin } from '@/mixins/dialog';
import { PrepressTypeEnum } from '@/resource/enum';
import { DepartmentResource, PersonnelResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { CascaderOption } from 'element-ui/types/cascader';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Redeploy extends mixins(DialogMixin) {
  @Prop({
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public idList!: Array<number>;

  @Prop({ type: Number, required: true })
  public type!: PrepressTypeEnum;

  public assignees: Array<PersonnelResource> = [];

  public departmentOptions: Array<CascaderOption> = [];

  public redeployForm: { userId: number | null; depId: number | null } = {
    userId: null,
    depId: null
  };

  public resourceFormRules = {
    userId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('product.selectClassify')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public dialogOpen(): void {
    this.getDepartments();
  }

  public dialogClosed(): void {
    (this.$refs.redeployForm as ElForm).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.redeployForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.type === PrepressTypeEnum.changeColor) {
        this.redeployChangeColorTask();
      } else {
        this.redeployCompleteManuscriptTask();
      }
    });
  }

  public departmentChanged(departmentId: number): void {
    if (departmentId) {
      this.getAssignees(departmentId);
    }
  }

  /**
   * 转派完稿任务
   */
  private redeployCompleteManuscriptTask(): void {
    completeManuscriptTaskService
      .redeploy(this.idList, this.redeployForm.userId!)
      .then(() => {
        this.$emit('redeploy-success');
        Message.success(translation('operationRes.operationSuccess'));
        this.closeDialog();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }

  /**
   * 转派改色任务
   */
  private redeployChangeColorTask(): void {
    changeColorTaskService
      .redeploy(this.idList, this.redeployForm.userId!)
      .then(() => {
        this.$emit('redeploy-success');
        Message.success(translation('operationRes.operationSuccess'));
        this.closeDialog();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }

  private getAssignees(departmentId: number): void {
    personnelService
      .getPersonnelsByDepId(departmentId)
      .then(res => {
        this.assignees = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async getDepartments(): Promise<void> {
    try {
      const resources = await departmentService.getList({});
      this.departmentOptions = this.handleCascaderOption(resources);
    } catch (error) {
      messageError(error);
    }
  }

  private handleCascaderOption(departments: Array<DepartmentResource>): Array<CascaderOption> {
    return departments.map(x => {
      const option: CascaderOption = {
        label: x.depName,
        value: x.id
      };
      if (x.children && x.children.length > 0) {
        option.children = this.handleCascaderOption(x.children);
      }
      return option;
    });
  }
}
